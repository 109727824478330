<script>
const propName = "value", eventName = "input"
export default {
  name: "InputSelect",
  model: {
    prop: propName,
    event: eventName
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    itemText: {
      type: String,
      default: "content",
    },
    hint: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "optionId",
    },
    [propName]: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
      required: true
    },
    multiple: {
      type: Boolean,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    savedValue: {
      validator: (v) => Array.isArray(v) || typeof v === 'string'|| typeof v === 'number' || v == null,
    },
    fieldId: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
    },
    multipleForms: {
      type: Boolean,
    },
    multipleFormsIndex: {
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      requiredRule: (v) => !!v || this.$t(`pages.application.rules.required-select${this.multiple ? '-multiple' : ''}`),
    }
  },
  computed: {
    model: {
      get() {
        return this[propName]
      },
      set(payload) {
        this.$emit(eventName, {
          fieldId: this.fieldId,
          value: payload
        })
      }
    },
    rules() {
      let rulesArray = []
      if (this.required) rulesArray.push(this.requiredRule)
      return rulesArray
    },
  },
  created() {
    let value
    if (!!this.savedValue && this.savedValue !== "None") {
      value = this.savedValue
    } else if (this.defaultValue) {
      value = this.defaultValue
    }
    if (value)
      this.model = this.multipleForms ? value[this.multipleFormsIndex] : value
  }
}
</script>

<template>
  <v-select
    v-model="model"
    :items="options"
    :item-text="itemText"
    :hint="hint"
    :rules="rules"
    :item-value="itemValue"
    :multiple="multiple"
    outlined
    flat
  />
</template>

<style scoped lang="scss">
</style>